import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/Layout"
import Seo from "../../components/seo"
import { BlogPagination } from "../../components/pagination"
import { BlogList } from "../../components/blog-list"

const IndexPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const tags = data.allMicrocmsTag.edges.map(x => x.node)
  const categories = data.allMicrocmsCategory.edges.map(x => x.node)
  const blogs=data.allMicrocmsBlog.edges
  
  return (
    <Layout title={siteTitle} tags={tags} categories={categories}>
    <BlogList blogs={blogs}/>
    <BlogPagination totalCount={data.allMicrocmsBlog.totalCount} current={0}/>
    </Layout>
  )
}

export default IndexPage

export const Head = ({data}) => {
  const url = data.allMicrocmsBlog.edges[0].node.thumbnail.url;
  return (<>
    <link rel="preload" href={url} 
imageSrcSet={url + "?auto=compress&fm=webp&w=550 600w, " + url + "?auto=compress&fm=webp&w=350, 5000w"} as="image"/>
    <Seo title="" description="うすかんがたまに執筆するウェブ技術・セキュリティ・ガジェットなどのブログです。"/>
  </>)
}
export const query = graphql`
  query BlogIndex {
    site {
      siteMetadata {
        title
      }
    }
    allMicrocmsBlog(sort: {fields: date, order: DESC}, limit: 5, skip:0) {
      totalCount
      edges {
        node {
          blogId
          title
          date
          publishedAt
          thumbnail {
            url
          }
          description
          tags {
            name
          }
          category {
            name
          }          
        }
      }
    }
    allMicrocmsCategory(limit: 30, skip:0) {
      totalCount
      edges {
        node {
          categoryId
          name
          blogsCount
        }
      }
    }
    allMicrocmsTag(limit: 100, skip:0) {
      totalCount
      edges {
        node {
          tagId
          name
          blogsCount
        }
      }
    }
  }
`